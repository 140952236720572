import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"
// const $ = require("jquery")

export const onInitialClientRender = () => {
  // // navbar
  // $(window).on("scroll", function () {
  //   if ($(this).scrollTop() > 170) {
  //     $(".navbar").addClass("is-sticky")
  //   } else {
  //     $(".navbar").removeClass("is-sticky")
  //   }
  // })
}
